import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { linkInstagram } from 'api/influencer/social';
import err from 'constants/config/error-messagess';
import routes from 'constants/routes';
import { toast } from 'react-toastify';

const Instagram = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (data) => {
      if (data.error || data.error_message) {
        throw data;
      }
      return linkInstagram({
        code: data.code,
        redirect_uri: process.env.REACT_APP_INSTA_REDIRECT_URL,
        igUserName: data.state,
      });
    },
    onSuccess: () => {
      navigate(routes.INF_SIGNUP_ADD_SOCIAL, { replace: true });
      toast.success('Instagram connected successfully');
    },
    onError: (res) => {
      if (res.error) {
        toast.error(err.UNABLE_TO_PROCESS);
      } else {
        toast.error(
          res.response?.data?.message ||
            res?.data?.message ||
            res?.message ||
            res?.error_message
        );
      }
      navigate(routes.INF_SIGNUP_ADD_SOCIAL, { replace: true });
    },
  });

  useEffect(() => {
    mutate(Object.fromEntries(searchParams));
  }, [searchParams, mutate]);

  return <div>Loading...</div>;
};

export default Instagram;
