import {
  formatFolloweGrowth,
  formatInfRank,
  formatInfSocialProfiles,
  formatNumber,
} from 'utils';
import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const searchInfluencers = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/campaign-influencer-list`,
    method: 'GET',
    params,
  });

export const getInfluencers = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/influencer-list`,
    method: 'GET',
    params,
  });

export const getInfluencersUpdated = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/influencers`,
    method: 'GET',
    params,
  });

export const getInfluencerDetails = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-social-media-influencer-detail`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      formatInfSocialProfiles(res?.data?.viewSocialMediaInfluencerDetailData),
  });

export const getInfluencerRank = ({ influencerId }) =>
  baseQuery({
    url: `/users/${apiVersion}/influencer-rank/${influencerId}`,
    method: 'GET',
    transformResponse: (res) => formatInfRank(res?.data.rank),
  });

export const getInfluencerBrands = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-brand-analysis`,
    method: 'GET',
    params,
  });

export const getInfCosts = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/view-cost`,
    method: 'GET',
    params,
  });

export const getCompareInfDetails = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/get-social-media-influencer-details`,
    method: 'POST',
    body,
  });

export const getFollowersGrowth = ({ platform, ...params }) =>
  baseQuery({
    url: `/users/${apiVersion}/get-follower-growth-${platform}`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      formatFolloweGrowth({ data: res?.data, platform }),
  });

export const getInfSubCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/influencer-categories-list`,
    method: 'GET',
    params,
  });

export const createCustomList = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/create-whishlist`,
    method: 'POST',
    body,
  });

export const addInfToCustomList = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/add-influencer-whishlist`,
    method: 'POST',
    body,
  });

export const getCustomLists = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-whishlist-list`,
    method: 'GET',
    params,
  });

export const getCustomListDetails = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-whishlist-detail`,
    method: 'GET',
    params,
  });

export const getTrendingCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-trending-categories`,
    method: 'GET',
    params,
  });

export const getTrendingSubCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-trending-subcategories`,
    method: 'GET',
    params,
  });

export const getTrendingInfluencers = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-trending-influencer-campaign`,
    method: 'GET',
    params,
  });

export const getInfCategories = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/categories-list`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.categoriesList?.rows?.map(
        ({
          category_name: name,
          category_type: type,
          id,
          category_image: image,
        }) => ({
          name,
          type,
          id,
          image,
        })
      ),
  });
export const brandCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand-category`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.result?.map(
        ({
          category_name: name,
          category_type: type,
          id,
          category_image: image,
        }) => ({
          name,
          type,
          id,
          image,
        })
      ),
  });
export const getSubCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-subcategory-list`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.stateListData?.map(
        ({
          subcategory_name: name,
          subcategory_type: type,
          id,
          categoryId,
        }) => ({
          name,
          type,
          id,
          categoryId,
        })
      ),
  });

export const getFollowersRange = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-follower-range`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.getFollowerRangeData?.map(
        ({ follower_from: from, follower_to: to, id }) => ({
          from,
          to,
          value: `${to}-${from}`,
          label: `${formatNumber(to)}-${formatNumber(from)}`,
          id,
        })
      ),
  });

export const getStateList = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-state-list`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.stateListData?.map(({ id, region_name: state, cities }) => ({
        name: state,
        id,
        cities,
      })),
  });

export const AddInfluencerSearch = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/add-influencer-by-search-master`,
    method: 'POST',
    body,
  });

export const updateInfluencerData = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/update-influencer-phyllo`,
    method: 'POST',
    body,
  });
