import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Fab,
  Card,
  Paper,
  Stack,
  Typography,
  Chip,
  Button,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Element } from 'react-scroll';
import {
  AudienceSection,
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfContentPerformanceAndBenchmark,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
  GrowthChart,
  BrandAffinityInterest,
  FollowerType,
} from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  instaReels,
  instaVideos,
  platformIcon,
  bgGrowthData,
  refreshIcon,
} from 'constants/images';
import { keyframes } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getInfluencerDetails,
  getInfluencerRank,
  updateInfluencerData,
} from 'api/brand/search';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';
import useFormatInstaAgeGenderData from 'hooks/useFormatInstaAgeGenderData';
import useFromatInstaCountryData from 'hooks/useFromatInstaCountryData';
import useFormatInstaCityData from 'hooks/useFormatInstaCityData';

const YoutubeProfile = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { id: influencerId } = useParams();

  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['brand-influencer-rank', influencerId],
    queryFn: () => getInfluencerRank({ influencerId }),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: [
        'brand-influencer-follower-growth',
        influencerId,
        'youtube',
        follersGrowthFilter,
      ],
      queryFn: () => {
        const params = {
          influencer_id: influencerId,
          follower_growth_filter: follersGrowthFilter,
          platform: 'youtube',
        };
        return getFollowersGrowth(params);
      },
    });
  console.log('🚀 ~ YoutubeProfile ~ followerGrowthData:', followerGrowthData);

  const { youtubeProfile } = influencer || {};
  const {
    username,
    shortsStats,
    videoStats,
    brandPosts,
    recentPosts,
    topPosts,
    hashtags,
    brands,
    audienceByAgeGroupAndGenderRaw,
    audienceByCountryRaw,
    audienceByCityRaw,
    growthDataRaw,
    audienceTypesRaw,
    audienceBrandsRaw,
    audienceInterestsRaw,
    audienceLanguagesRaw,
    audienceEthnicitiesRaw,
    formattedCreatorInterests,
    frequentlyTaggedAccounts = [],
  } = youtubeProfile || {};

  const contentPerformance = useMemo(
    () =>
      recentPosts?.map((video) => ({
        videoId: video.postId,
        views: Number(video.viewsCount),
      })),
    [recentPosts]
  );

  const { audienceByAgeGroup, audienceByGender } = useFormatInstaAgeGenderData(
    audienceByAgeGroupAndGenderRaw
  );
  const audienceByCountry = useFromatInstaCountryData(audienceByCountryRaw);
  const { audienceByCity } = useFormatInstaCityData(audienceByCityRaw);

  const updateInfluencerMutation = useMutation(updateInfluencerData, {
    onSuccess: () => {
      window.location.reload();
    },
    onError: (error) => {
      console.error('Error updating influencer data:', error);
    },
  });

  const handleGetMoreData = async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    try {
      await updateInfluencerMutation.mutateAsync({
        youtube_id: youtubeProfile?.username.replace('@', ''),
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const [isRefreshing, setIsRefreshing] = useState(false);
  const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          // { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Subscribers"
          value={formatNumber(youtubeProfile?.followers)}
        />
        <OverviewCard
          title="Engagement Rate"
          value={formatNumber(youtubeProfile?.avgEngagementRate)}
        />
        <OverviewCard title="Sponsor Post Performance" value={0} />

        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(youtubeProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Average Likes"
          value={formatNumber(youtubeProfile?.avgLikes)}
        />
        <OverviewCard
          title="Average Comments"
          value={formatNumber(youtubeProfile?.avgComments)}
        />
        <OverviewCard
          title="Average Views"
          value={formatNumber(youtubeProfile?.avgViews)}
        />
        <OverviewCard
          title="Total Media"
          value={formatNumber(youtubeProfile?.totalMedia)}
        />
      </Element>
      <Element
        className={`${styles.navCategory} ${styles.engagementSectionPadding}`}
        name="engagement"
      >
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={instaVideos}
            title="Videos"
            items={[
              // {
              //   name: 'Total Videos',
              //   value: formatNumber(videoStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(videoStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(videoStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(videoStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(videoStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
          <EngagementCard
            icon={instaReels}
            title="Shorts"
            items={[
              // {
              //   name: 'Total Shorts',
              //   value: formatNumber(shortsStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(shortsStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(shortsStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(shortsStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(shortsStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
        </div>
      </Element>
      {/* <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element> */}

      {/* Growth Charts */}
      {youtubeProfile?.growthDataRaw &&
        youtubeProfile?.growthDataRaw.length > 0 && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ width: '100%' }}
          >
            {youtubeProfile?.growthDataRaw.some(
              (item) => item.subscribers > 0
            ) && (
              <GrowthChart
                title="Subscribers Growth"
                subtitle="Monthly Subscribers Trend"
                data={youtubeProfile?.growthDataRaw}
                xKey="date"
                yKey="subscribers"
                color="#7C087F"
              />
            )}
            {youtubeProfile?.growthDataRaw.some((item) => item.likes > 0) && (
              <GrowthChart
                title="Likes Growth"
                subtitle="Monthly Like Trend Per Post"
                data={youtubeProfile?.growthDataRaw}
                xKey="date"
                yKey="likes"
                color="#7C087F"
              />
            )}
          </Stack>
        )}

      <AudienceSection
        audienceByCity={audienceByCity}
        audienceByCountry={audienceByCountry}
        audienceByGender={audienceByGender}
        audienceByAgeGroup={audienceByAgeGroup}
      />

      <InfContentPerformanceAndBenchmark
        data={contentPerformance}
        valueKey="views"
        influencerAverage={youtubeProfile?.avgViews}
      />

      <Element name="content">
        <ContentSection
          brandPosts={brandPosts}
          hashtags={hashtags}
          recentPosts={recentPosts}
          topPosts={topPosts}
        />
      </Element>
      <Element name="brands">
        <BrandsSection
          brands={brands}
          platform="youtube"
          variant="brand"
          influencerId={influencerId}
        />
      </Element>
      {!youtubeProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Profile is under review." description="" />
        </div>
      )}

      {/* Add the Glass Morphism Blur Section Here */}
      {(youtubeProfile.isDataFetched && !growthDataRaw) ||
      growthDataRaw.length === 0 ? (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '300px',
            marginTop: '20px',
            backgroundImage: `url(${bgGrowthData})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(5px)',
              WebkitBackdropFilter: 'blur(5px)',
              borderRadius: '12px',
            }}
          />

          {/* Content on Top of the Glass Morphism */}
          <div
            style={{
              position: 'relative',
              zIndex: 2, // Ensure this is above the frosted glass
              textAlign: 'center',
            }}
          >
            <Button
              className={styles.addList}
              variant="contained"
              color="primary"
              onClick={handleGetMoreData}
            >
              Get More Data
            </Button>
          </div>
        </div>
      ) : null}

      <Tooltip title="Get Latest Data" arrow>
        <Fab
          color="primary"
          aria-label="refresh"
          onClick={handleGetMoreData}
          disabled={isRefreshing}
          sx={{
            position: 'fixed',
            top: 50,
            right: 50,
            zIndex: 1000,
            backgroundColor: '#a506aa',
            '&:hover': { backgroundColor: '#7c087f' },
          }}
        >
          {isRefreshing ? (
            <img
              src={refreshIcon}
              alt="Refreshing"
              width="24"
              height="24"
              style={{
                animation: `${spinAnimation} 1s linear infinite`,
              }}
            />
          ) : (
            <img src={refreshIcon} alt="Refresh" width="24" height="24" />
          )}
        </Fab>
      </Tooltip>
    </section>
  );
};

export default YoutubeProfile;
