import { unlinkInstagramAPI } from '../api/influencer/social';

const useInstagramAuth = () => {
  const onInstagramLogin = () => {
    const igUsername = document.getElementsByName('instagramId')[0].value
      ? document.getElementsByName('instagramId')[0].value
      : ' ';
    const instagramAuthUrl = new URL(
      'https://api.instagram.com/oauth/authorize'
    );

    instagramAuthUrl.searchParams.append('display', 'page');
    instagramAuthUrl.searchParams.append('response_type', 'code');
    instagramAuthUrl.searchParams.append('state', igUsername);
    instagramAuthUrl.searchParams.append(
      'client_id',
      process.env.REACT_APP_INSTA_CLIENT_ID
    );
    instagramAuthUrl.searchParams.append(
      'extras',
      JSON.stringify({
        setup: { channel: 'IG_API_ONBOARDING' },
      })
    );
    instagramAuthUrl.searchParams.append(
      'redirect_uri',
      process.env.REACT_APP_INSTA_REDIRECT_URL
    );
    instagramAuthUrl.searchParams.append(
      'scope',
      process.env.REACT_APP_INSTA_AUTH_SCOPE
    );
    window.location = instagramAuthUrl;
  };

  const unlinkInstagram = () => {
    // call to API to remove instagram account
    unlinkInstagramAPI();
  };

  return { onInstagramLogin, unlinkInstagram };
};

export default useInstagramAuth;
